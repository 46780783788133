import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';

import TextImage from '../../components/blog/TextImage';
import List from '../../components/blog/List';
import Text from '../../components/blog/Text';

const components = {TextImage, List, Text};

const MDXLayout = ({children}: any) => {
  return <MDXProvider components={components}>{children}</MDXProvider>;
};

export default MDXLayout;

export const MDXContent = ({children, img}) => (
  <MDXRenderer img={img}>{children}</MDXRenderer>
);
