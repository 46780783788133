import React from 'react';
import {navigate} from 'gatsby';
import {styled} from 'twin.macro';
import GatsbyBackgroundImage from '../../shared/GatsbyBackgroundImage';
import {useI18next} from 'gatsby-plugin-react-i18next';

const ListItem = styled.article`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  border-radius: 20px;
  min-height: 450px;
  margin-bottom: 30px;
  cursor: pointer;
  color: black;
  margin-bottom: 20px;

  @media ${props => props.theme.screens.md} {
    width: 48%;
    margin-bottom: 40px;
  }

  h1 {
    color: ${props => props.theme.colors.primary};
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  small {
    font-weight: 300;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const FlexColumn = styled.div<React.CSSProperties>`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  height: ${props => props.height};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 15px 20px;
  background-color: #efefefe5;
  backdrop-filter: blur(11px);
  opacity: 0.9;
  min-height: 190px;
  margin-bottom: 0px;
  border-radius: 0 0 20px 20px;
`;

const ArticleItem = ({post}: any) => {
  const {language, t} = useI18next();
  return (
    <ListItem
      itemScope
      itemType="http://schema.org/Article"
      onClick={() => {
        navigate(
          `${language === 'en' ? '/en' : ''}/blog${post?.frontmatter?.slug}`,
        );
      }}
    >
      <GatsbyBackgroundImage
        image={post?.frontmatter?.coverImg?.childImageSharp}
        wrapperStyle={{margin: 0, pointerEvents: 'none', borderRadius: '20px'}}
        imgStyle={{zIndex: -101, borderRadius: '20px'}}
      >
        <FlexColumn justifyContent="flex-end" height="100%">
          <ContentWrapper>
            <FlexColumn justifyContent="space-between" height="100%">
              <h1 itemProp="headline" style={{color: 'black'}}>
                {post?.frontmatter?.title || post?.frontmatter?.slug}
              </h1>
              <small style={{color: 'black', fontSize: '21px'}}>
                {t('blog_articles_read_more')}
              </small>
            </FlexColumn>
          </ContentWrapper>
        </FlexColumn>
      </GatsbyBackgroundImage>
    </ListItem>
  );
};

export default ArticleItem;
