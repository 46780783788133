import React from 'react';
import {styled} from 'twin.macro';
import {Container} from '../Grid';

const StyledText = styled.p`
  font-family: 'avertaFont';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: black;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};

  a {
    color: #039259;
    text-decoration: underline;
  }
`;
const Text = ({content, style}) => (
  <Container>
    <StyledText dangerouslySetInnerHTML={{__html: content}} {...style} />
  </Container>
);

export default Text;
