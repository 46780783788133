import { theme } from 'twin.macro';
import useWindowSize from './useWindowSize';

function useWindowBreakpoints() {
  const { width = 0 } = useWindowSize() || {};

  const sm = Number(theme('screens.sm').replace('px', ''));

  const md = Number(theme('screens.md').replace('px', ''));
  const lg = Number(theme('screens.lg').replace('px', ''));
  const xl = Number(theme('screens.xl').replace('px', ''));
  // https://tailwindcss.com/docs/responsive-design
  const breakpoints = {
    isReallySm: width <= sm,
    isStrictlySM: width > sm && width <= md,
    isStrictlyMD: width > md && width <= lg,
    isStrictlyLG: width > lg && width < xl,
    isSM: width > sm,
    isMD: width > md,
    isLG: width > lg,
    isXL: width > xl,
  };

  return breakpoints;
}

export default useWindowBreakpoints;
