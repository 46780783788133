import tw, {styled} from 'twin.macro';

export const Heading = styled.h2`
  ${tw`md:text-heading md:leading-heading text-primary`}

  font-size: 30px;
  line-height: 40px;

  @media ${props => props.theme.screens.md} {
    font-size: 45px;
    line-height: 56px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 45px;
    line-height: 56px;
  }
`;

export const Text = styled.p`
  ${tw`md:text-paragraph md:leading-paragraph text-200 font-medium font-medium md:font-bold `}

  font-size: 15px;
  line-height: 24px;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
    line-height: 31px;
    margin-bottom: 10px;
  }
`;
